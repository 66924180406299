import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "src/contexts/AuthContext";

export const useVerifyConnect = () => {
    const navigate = useNavigate();
    const {user} = useAuth();
  
    const verifyConnect = () => {
      const api_token = user();
      if (api_token === undefined || api_token === null) {
        return navigate('/', { replace: true });
      }
    }

    useEffect(() => {
      verifyConnect();
    }, []);
}