import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

interface User {
  user: {
    id: number;
    name: string;
    email: string;
    type: string;
  },
  api_token: string;
}

interface AuthContext {
  signIn(data: User): Promise<void>;
  signOut(): void;
  user(): User;
}

const AuthContext = createContext<AuthContext>({} as AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const signIn = async (data: User): Promise<void> => {
    localStorage.setItem('user', JSON.stringify(data))
    return navigate("/dashboard/home", { replace: true });
  }

  const signOut = async () => {
    localStorage.removeItem('user');
    return navigate("/", { replace: true });
  }

  const user = () => {
    const data = localStorage.getItem('user');
    const dataFormated = JSON.parse(data);
    return dataFormated;
  }

  return <AuthContext.Provider value={{ signIn, signOut, user }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
